import mapKeys from "lodash/mapKeys"
import { type GtpPermission } from "shared-utils"

import { NEXT_PUBLIC_ENV } from "@/config/client"
import { type TEnvirontment } from "@/types"

type DashboardTypeItem = {
  id: { [key in TEnvirontment | "default"]?: number }
  params?: { [key: string]: boolean }
  slug?: string
  permissions?: GtpPermission[]
}

type DashboardType = {
  perubahanHarga: DashboardTypeItem
  ppkKePenyediaYangSamaDanBerulang: DashboardTypeItem
  kecepatanTransaksiPertama: DashboardTypeItem
  kecepatanStatusSuatuTransaksi: DashboardTypeItem
  kesesuaianTKDN: DashboardTypeItem
  kewajaranHargaProduk: DashboardTypeItem
  pembandingProduk: DashboardTypeItem
  pembandingTransaksi: DashboardTypeItem
  detilPerubahanHargaNasional: DashboardTypeItem
  detailTransaksiModusRatioSeller: DashboardTypeItem
  detailInformasiProduk: DashboardTypeItem
  detilKecepatanStatusPaket: DashboardTypeItem
  substitusiProdukImpor: DashboardTypeItem
  ringkasanAnomali: DashboardTypeItem
  akunINAPROC: DashboardTypeItem
  pengawasanTraksaksiV6: DashboardTypeItem
  profilPenyedia: DashboardTypeItem
  pengawasanProdukV6: DashboardTypeItem
}

const dashboardMap: DashboardType = {
  perubahanHarga: {
    id: {
      production: 76,
      default: 40,
    },
    slug: "perilaku-perubahan-harga",
    params: {
      klpd: true,
      satker: true,
    },
    permissions: ["eaudit:view"],
  },
  ppkKePenyediaYangSamaDanBerulang: {
    id: {
      production: 81,
      default: 44,
    },
    slug: "perilaku-transaksi-ppk-ke-penyedia-yang-sama-dan-berulang",
    params: {
      klpd: true,
      satker: true,
    },
    permissions: ["eaudit:view"],
  },
  kecepatanTransaksiPertama: {
    id: {
      production: 80,
      default: 37,
    },
    slug: "perilaku-kecepatan-transaksi-pertama-suatu-produk",
    params: {
      klpd: true,
      satker: true,
    },
    permissions: ["eaudit:view"],
  },
  kecepatanStatusSuatuTransaksi: {
    id: {
      production: 74,
      default: 36,
    },
    slug: "perilaku-kecepatan-status-suatu-transaksi",
    params: {
      klpd: true,
      satker: true,
    },
    permissions: ["eaudit:view"],
  },
  pembandingProduk: {
    id: {
      production: 84,
      default: 67,
    },
    slug: "pembanding-produk",
    permissions: ["eaudit:view"],
  },
  pembandingTransaksi: {
    id: {
      production: 85,
      default: 66,
    },
    slug: "pembanding-transaksi",
    permissions: ["eaudit:view"],
  },
  detilPerubahanHargaNasional: {
    id: {
      production: 77,
      default: 55,
    },
    params: {
      klpd: true,
      satker: true,
    },
    permissions: ["eaudit:view"],
  },
  detailTransaksiModusRatioSeller: {
    id: {
      production: 82,
      default: 50,
    },
    params: {
      klpd: true,
      satker: true,
    },
    permissions: ["eaudit:view"],
  },
  detailInformasiProduk: {
    id: {
      production: 79,
      default: 59,
    },
    params: {
      klpd: true,
      satker: true,
    },
    permissions: ["eaudit:view"],
  },
  ringkasanAnomali: {
    id: {
      production: 255,
      default: 202,
    },
    params: {
      klpd: true,
    },
    slug: "summary",
    permissions: ["eaudit:view"],
  },
  detilKecepatanStatusPaket: {
    id: {
      production: 75,
      default: 38,
    },
    params: {
      klpd: true,
      satker: true,
    },
    permissions: ["eaudit:view"],
  },
  profilPenyedia: {
    id: {
      default: 251,
    },
    slug: "profil-penyedia",
    permissions: ["eaudit:view"],
  },
  pengawasanTraksaksiV6: {
    id: {
      default: 262,
    },
    slug: "pengawasan-transaksi",
    permissions: ["eaudit:view"],
  },
  pengawasanProdukV6: {
    id: {
      default: 265,
    },
    slug: "pengawasan-produk",
    permissions: ["eaudit:view"],
  },
  kesesuaianTKDN: {
    id: {
      production: 99,
      default: 26,
    },
    slug: "patroli-kesesuaian-tkdn",
    permissions: ["monev:view"],
  },
  kewajaranHargaProduk: {
    id: {
      production: 95,
      default: 42,
    },
    slug: "ketidakwajaran-harga-v6",
    permissions: ["monev:view"],
  },
  substitusiProdukImpor: {
    id: {
      production: 97,
      default: 47,
    },
    slug: "dashboard-substitusi-produk-impor-v6",
    permissions: ["monev:view"],
  },
  akunINAPROC: {
    id: {
      default: 246,
    },
    params: {
      klpd: true,
      satker: true,
    },
    slug: "persona-klpd",
    permissions: ["monev:view"],
  },
}

export const getDashboardConfig = (id: number) => {
  const configMapById = mapKeys(
    dashboardMap,
    (value) => value.id[NEXT_PUBLIC_ENV] || value.id["default"]
  )
  return configMapById[id]
}

export const getDashboardId = (id: keyof DashboardType) => {
  const config = dashboardMap[id]?.id
  return config?.[NEXT_PUBLIC_ENV] || config?.["default"]
}

export const getDashboardUrl = (id: keyof DashboardType) => {
  const slug = dashboardMap[id]?.slug
  return slug ? `${getDashboardId(id)}-${slug}` : getDashboardId(id)
}
