import { IS_PRODUCTION } from "shared-utils"

export const ENTERPRISE_VALIDATION = "MARKETPLACE-ENTERPRISE_VALIDATION"

export const PRODUCT_PRINCIPAL_SECTORAL =
  "MARKETPLACE-PRODUCT_PRINCIPAL_SECTORAL"

export const METABASE_AKUN_INAPROC = "ACCOUNT-METABASE-AKUN-INAPROC"

export const SHOW_SOCKET_GET_UPLOAD_STATUS_V1 =
  "ACCOUNT-KILL_SWICTH_AUTH_UPLOAD_STATUS_SOCKET"

export const MULTISHIPMENT = "MULTISHIPMENT"

export const MULTISHIPMENT_JASA_DIGITAL = "MULTISHIPMENT_JASA_DIGITAL"

export const ORDER_CONFIRMATION_DELIVERY_DOCUMENT =
  "ORDER-SERVICEDIGITAL_CONFIRMATION_DELIVERY_DOCUMENT"

export const GRADUALLY_WORKING_PROGRESS = "GRADUALLY-WORKING-PROGRESS"

export const MARKETPLACE_MINICOM_ADJUSTMENT = "MARKETPLACE-MINICOM_ADJUSTMENT"

export const MARKETPLACE_NEW_TAX_CALCULATION = "MARKETPLACE-NEW_TAX_CALCULATION"

export const AUDIT_E_KATALOG_V6 = !IS_PRODUCTION

export const MARKETPLACE_MASTER_PRODUCT = "MARKETPLACE-MASTER_PRODUCT"

export const MARKETPLACE_INTERNAL_PRODUCT_TAX_SIMULATION =
  "MARKETPLACE-INTERNAL_PRODUCT_TAX_SIMULATION"
